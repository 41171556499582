import React, { useState } from 'react';
import 'devextreme-react/text-area';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { TextBox } from 'devextreme-react/text-box';
import { Button } from 'devextreme-react';
import { useAuth } from '../../../contexts/auth';
import { useScreenSize } from '../../../utils/media-query';
import ValidationSummary from 'devextreme-react/validation-summary';
import {
  Validator,
  RequiredRule,
  CompareRule,
  EmailRule,
  PatternRule,
  StringLengthRule,
  RangeRule,
  AsyncRule,
} from 'devextreme-react/validator';


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export default function ResetPassword() {

  const { user, SetUser } = useAuth();
  const [oldpassword, SetoldPassword] = useState(null);
  const [newPassword, SetNewPassword] = useState('');
  const [confirmPassword, SetConfirmPassword] = useState(null);
  const [showPassword, setShowPassword] = useState();
  const { isXSmall, isLarge } = useScreenSize();
  var UserCode = user.UserCode;

  const handleoldPassword = (e) => {
    SetoldPassword(e.value);
  }
  const handleNewPassword = (e) => {
    SetNewPassword(e.value);
  }
  const handleConfirmpassword = (e) => {
    SetConfirmPassword(e.value);
  }

  const handlePasswordChanged = (e) => {
    if (oldpassword == '') {
      notify({
        message: 'Old Password Should not be Empty',
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'warning', 3000);
      return;
    }
    if (newPassword == '' && confirmPassword == '') {
      notify({
        message: 'New Password & Confirm Password Should not be Empty',
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'warning', 3000);
      return;
    }
    if (newPassword != confirmPassword) {
      notify({
        message: 'New Password & Confirm Password Should be Match',
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'warning', 3000);
      return;
    }

    axios({
      method: 'post',
      url: `${apiBaseUrl}/User/UpdatePassword`,
      data: { UserCode: UserCode, Password: oldpassword, NewPassword: newPassword }
    }).then(function (response) {
      if (response.data.Result == "Success") {
        SetoldPassword('');
        SetNewPassword('');
        SetConfirmPassword('');
        notify({
          message: 'Password is changed successfully',
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
      } else {
        notify({
          message: response.data.Result,
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'error', 3000);
      }
    }).catch((error) => {
      notify({
        message: "Error fetching data from API:" + error,
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'error', 3000);
      return [];
    });
  }

  const onFormSubmit = (e) => {
    if (oldpassword == null) {
      notify({
        message: 'Old Password Should not be Empty',
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'warning', 3000);
      return;
    }
    if (newPassword == '') {
      notify({
        message: 'New Password Should not be Empty',
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'warning', 3000);
      return;
    }
    if (confirmPassword == '') {
      notify({
        message: 'Confirm Password Should not be Empty',
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'warning', 3000);
      return;
    }
    if (newPassword != confirmPassword) {
      notify({
        message: 'New Password & Confirm Password Should be Match',
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'warning', 3000);
      return;
    }

    axios({
      method: 'post',
      url: `${apiBaseUrl}/User/UpdatePassword`,
      data: { UserCode: UserCode, Password: oldpassword, NewPassword: newPassword, User: UserCode }
    }).then(function (response) {
      if (response.data.Result == "Success") {
        SetoldPassword('');
        SetNewPassword('');
        SetConfirmPassword('');
        notify({
          message: 'Password is changed successfully',
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);
      } else {
        notify({
          message: response.data.Result,
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'error', 3000);
      }
    }).catch((error) => {
      notify({
        message: "Error fetching data from API:" + error,
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'error', 3000);
      return [];
    });
    //e.preventDefault();
  }
  return (
    <React.Fragment>

      <h4 className={'content-block'}>Update Password</h4>
      <form>
        <div className="dx-fieldset" style={{ width: isXSmall ? `90%` : `50%` }}>

          <div className="dx-field">
            <div className="dx-field-label">Old Password</div>
            <div className="dx-field-value">
              <TextBox mode={showPassword} value={oldpassword} onValueChanged={handleoldPassword} >
                <Validator>
                  <RequiredRule message="Old Password is required" />
                </Validator>
              </TextBox>
            </div>
          </div>

          <div className="dx-field">
            <div className="dx-field-label">New Password</div>
            <div className="dx-field-value">
              <TextBox value={newPassword} mode='password' onValueChanged={handleNewPassword} >
                <Validator>
                  <RequiredRule message="New Password is required" />
                </Validator>
              </TextBox>
            </div>
          </div>

          <div className="dx-field">
            <div className="dx-field-label">Confirm Password</div>
            <div className="dx-field-value">
              <TextBox value={confirmPassword} mode='password' onValueChanged={handleConfirmpassword} >
                <Validator>
                  <RequiredRule message="Confirm Password is required" />
                </Validator>
              </TextBox>
            </div>
          </div>

          <div style={{ paddingTop: 30, textAlign: 'center' }}>
            <ValidationSummary id="summary" />
            {/* <Button text={'Update'} onClick={handlePasswordChanged} type="success" stylingMode="contained" /> */}
            <Button
              id="button"
              text="Update"
              type="success"
              useSubmitBehavior={false} 
              onClick={onFormSubmit}
              />
          </div>

        </div>
      </form>


    </React.Fragment >
  )
};

