export const navigation = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    icon: 'home'
  },

  //Master

  {
    text: 'Master',
    icon: 'inactivefolder',
    items: [
      {
        text: 'Area',
        path: '/area',
      },
      {
        text: 'Product',
        path: '/product',
      },
      {

        text: 'User',
        path: '/user',
      }
    ]
  },

  {
    text: 'Customer',
    icon: 'user',
    items: [
      {
        text: 'Customer Details',
        path: '/customers',
      },
    ]
  },
];

export const usernavigation = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    icon: 'home'
  },
  {
    text: 'Customer',
    icon: 'user',
    items: [
      {
        text: 'Customer Details',
        path: '/customers',
      },
    ]
  },
];
